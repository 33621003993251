const MantraData = [
  {
    SrNo: 1,
    mantra:
      "श्रीं ह्रीं कट् रक्तचामुण्डीश्वरी शत्रु जीवविनाशनि एहोहि शीघ्रं इष्टनाकर्षयाकर्षय स्वाहा ॥",
    purpose: "जेल बन्धन से मुक्ति पाने क मन्त्र।",
    ritual: "",
  },
  {
    SrNo: 2,
    mantra: (
      <p style={{ lineHeight: "22px" }}>
        इं ओं नमो भगवति श्रीं ह्रीं शारदा देवी क्रीं अनन्तातुल् भोज्यं देहि देहि{" "}
        <br />
        रहागच्छागच्छागन्तुकं ह्रदयस्थं कार्यं सत्यं ब्रुहि सत्यं ब्रुहि
        पुलिन्दनि ईं स्वाहा ॥{" "}
      </p>
    ),
    purpose: "मुकदमे मे जीत का मन्त्र",
    ritual: "आकाश भैरव के 9 वे अध्याय के 21 पाठ व 2 पाठ से हवन",
  },
  {
    SrNo: 3,
    mantra: "क्लीं ग्लों क्लीं श्यामलांगाय नमः॥",
    purpose: "घर की सुख्: शान्ति के लिये",
    ritual: "गुरुवार 16 / 5100 मन्त्र हर गुरुवार",
  },
  {
    SrNo: 4,
    mantra:
      "ॐ श्रीं हीं क्लीं ग्लीं गं गणपते वर वरद सर्वजनं मे वशमानय स्वाहा ॥",
    purpose: "ग्रह शान्ति व ग्रह दोष निवारण",
    ritual: "offline पूजा व हवन आकाश भैरव कल्प 7 पाठ व 1 पाठ का हवन करे.",
  },
  {
    SrNo: 5,
    mantra:
      "ॐ देवकी सुत गोविन्द वासुदेव जगत्पते, देहि मे तनयं कृष्ण त्वामहं शरणंगतः ॥",
    purpose: "गर्भस्थ शिशु की रक्षा के लिये मन्त्र",
    ritual: "",
  },
  {
    SrNo: 6,
    mantra: (
      <p style={{ lineHeight: "22px" }}>
        हां का 2 लाख बार मन्त्र जप कुष्ठ रोग के लिये व 3 लाख बार मन्त्र जप यश्मा
        के लिये <br />व 4 लाख बार मन्त्र जप भयंकर व अशाध्य रोगो के{" "}
      </p>
    ),
    purpose: "कुष्ठ रोग यश्मा आदि के लिये भगवान सूर्य देव का मन्त्र",
    ritual: "",
  },
  {
    SrNo: 7,
    mantra: (
      <p style={{ lineHeight: "22px" }}>
        ॐ ह्रें श्रीं हुं दुं इन्द्राक्षी ! मां रक्ष रक्ष मम शत्रुन् नाशय नाशय ,
        जलरोगान शोषय -शोषय त्ररानरिन भजंय-भजंय, <br />
        दुख:-व्याधीन स्फोटय स्फोटेय मनोग्रन्थि प्राणग्रन्थि शिरोग्रन्थीन काटय
        काटय , इन्द्राक्षी मां रक्ष रक्ष हुं फट् स्वाहा
      </p>
    ),
    purpose: "असहाय रोगो के निवारण हेतु मन्त्र",
    ritual: (
      <p style={{ lineHeight: "20px" }}>
        Offline Pooja And Havan आकाश भैरव कल्प के <br />
        15 वे अध्याय के 17 पाठ व 2 पाठ के हवन{" "}
      </p>
    ),
  },
  {
    SrNo: 8,
    mantra: "हीं वेदमातृभ्य: स्वाहा I ओम सं सरस्वती स्वाहा ॥",
    purpose: "विध्या मे विफ़लता / उच्च शिक्षा प्राप्ति के लिये",
    ritual: "आकाश भैरव कल्प के 11 वे अध्याय के 21 पाठ व 2 पाठ के हवन",
  },
  {
    SrNo: 9,
    mantra:
      "ॐ हीं बगलामुखि सर्वदुष्टानाम् वाचं I मुखं पदं स्तम्मय जिव्हां कीलय, बुद्धि विनाशाय, हीं ॐ स्वाहा ॥",
    purpose: "चुनाव जितने का मन्त्र",
    ritual:
      "Offline पूजा व हवन आकाश भैरव कल्प के 11 वे अध्याय के 21 पाठ व 2 पाठ के हवन",
  },
  {
    SrNo: 10,
    mantra:
      "ओं श्रीं क्लीं महालक्ष्मी महालक्ष्मी एहेहि सर्वसौभाग्यं देहि मे स्वाहा ॥",
    purpose: "कर्ज मुक्ति का मन्त्र",
    ritual:
      "Offline पूजा व हवन आकाश भैरव कल्प के 28 वे अध्याय के 21 पाठ व 2 पाठ के हवन",
  },
  {
    SrNo: 11,
    mantra:
      "ॐ भूर् भुवः स्वः। तत् सवितुर्वरेण्यं। भर्गो देवस्य धीमहि। धियो यो नः प्रचोदयात् ॥",
    purpose: "मांगलिक दोष निवारण मन्त्र",
    ritual:
      "Offline पूजा व हवन आकाश भैरव कल्प के 56 वे अध्याय के 31 पाठ व 3 पाठ के हवन",
  },
  {
    SrNo: 12,
    mantra: "श्री धुमावति मालमंत्र",
    purpose: "सर्वसिद्धि प्रदायक मन्त्र",
    ritual:
      "Offline पूजा व हवन आकाश भैरव कल्प के 27 वे अध्याय के 31 पाठ व 3 पाठ के हवन",
  },
  {
    SrNo: 13,
    mantra: "ॐ हीं क्लीं हुं मातग्ये फट स्वाहा ॥",
    purpose: "विदेश यात्रा व विदेश मे नौकरी के लिये",
    ritual:
      "Offline पूजा व हवन आकाश भैरव कल्प के 25 वे अध्याय के 21 पाठ व 2 पाठ के हवन",
  },
  {
    SrNo: 14,
    mantra: "युद्र यामल तन्त्र के अनुसार , महामृत्युन्ज्य मालामन्त्र ॥",
    purpose: "मृत्यु योग व अकाल मृत्यु से बचने का मन्त्र",
    ritual: "",
    //   "Offline पूजा व हवन आकाश भैरव कल्प के 43 वे अध्याय के 41 पाठ व 4 पाठ के हवन",
  },
  {
    SrNo: 15,
    mantra:
      "त्रिलोक्यपूजिते देवी , कमले विष्णुवल्लभे I यथा त्वमअचला कृष्णे , तथा भवमयि स्थिरा II",
    purpose: "स्थिर लक्ष्मी प्राप्त करने के लिये",

    ritual:
      "Offline पूजा व हवन  आकाश भैरव कल्प के 43 वे अध्याय के 41 पाठ व 4 पाठ के हवन  ",
  },
  {
    SrNo: 16,
    mantra: (
      <p style={{ lineHeight: "22px" }}>
        ओम क्लीं ऐं हीं श्रीं नमो भगवति संतान कामेश्वरी गर्भविरोधं निरासय निरासय
        सम्यक
        <br /> शीघ्रं सन्तानमुत्पादयोत्पादय स्वाहा ॥{" "}
      </p>
    ),
    purpose: "संतान प्राप्ति मन्त्र",
  },
  {
    SrNo: 17,
    mantra: (
      <p style={{ lineHeight: "22px" }}>
        ॐ ऐं क्लीं क्लीं क्लुं हां हीं हुं सः वं आपदुद्वारणाय अजामलवधाय
        लोकेश्वराय स्वर्णाकर्षण
        <br /> भैरवाय मम् दारिद्रय विद्वेषणाय ॐ ह्रीं महाभैरवाय नमः ॥
      </p>
    ),
    purpose: "खोया हुआ धन व छिपे हुये खजाने / सोने की प्राप्ति के लिये ",
    ritual:
      " Offline पूजा व हवन आकाश भैरव कल्प के 41 वे अध्याय के 31 पाठ व 3 पाठ के हवन",
  },
  {
    SrNo: 18,
    mantra: "आकाश भैरव कल्प के 31 वे अध्याय के 27 पाठ व 3 पाठ के हवन",
    purpose: "शत्रु मारणम्",
    ritual:
      "Offline पूजा व हवन आकाश भैरव कल्प के 31 वे अध्याय के 27 पाठ व 3 पाठ के हवन",
  },
  {
    SrNo: 19,
    mantra: (
      <p style={{ lineHeight: "22px" }}>
        {" "}
        हीं क्लीं ओं नमो भगवति महामोहिनी महामये सर्वलोक वशंकरि देवदत्तस्य वाक्
        <br /> चक्षुस्थितं मोहय मोहय नानारूपाकृतिः शीघ्रं दर्शय दर्शय हीं स्वाहा
        ॥{" "}
      </p>
    ),
    purpose: "सर्वजन् मोहित करने का मन्त्र",
    ritual:
      "Offline पूजा व हवन  आकाश भैरव कल्प के 44 वे अध्याय के 21 पाठ व 2 पाठ के हवन",
  },
  {
    SrNo: 20,
    mantra: (
      <p style={{ lineHeight: "22px" }}>
        भीं ठं द्रां नमो भगवति जगन्मोहिनी सोमेश्वरी <br />
        सर्व लोकाक्षि -हच्द्रोत्रं द्रावय -द्रावय स्वाहा ॥{" "}
      </p>
    ),
    purpose: "सभी जन को अपने हित मे करने का मन्त्र",
    ritual:
      "Offline पूजा व हवन  आकाश भैरव कल्प के 45 वे अध्याय के 21 पाठ व 2 पाठ के हवन",
  },
  {
    SrNo: 21,
    mantra: "आकाश भैरव कल्प के 36 वे अध्याय के 31 पाठ व 3 पाठ के हवन ॥",
    purpose: "वास्तु दोष निवारन मन्त्र",
    ritual: (
      <p style={{ lineHeight: "22px" }}>
        {" "}
        पूजा व हवन Offline पूजा व हवन आकाश भैरव कल्प के 36 वे अध्याय के 31 पाठ{" "}
        <br />व 3 पाठ के हवन{" "}
      </p>
    ),
  },
  {
    SrNo: 22,
    mantra:
      "पामों नमो भगवति शब्दाकर्षिणी  देवि अमिष्ट वरदे सर्वलोक मोहिनी सर्वमये शब्दाना कर्षयस्वाहा ॥",
    purpose: "वाणी की सिद्धि का मन्त्र",
    ritual:
      "Offline पूजा व हवन आकाश भैरव कल्प के 46 वे अध्याय के 21 पाठ व 2 पाठ के हवन",
  },
  {
    SrNo: 23,
    mantra:
      "ओम श्रीं क्लीं महालक्ष्मी महालक्ष्मी एक्षेहि सर्व सौभाग्यं देहि मे स्वाहा II",
    purpose: "सौभाग्य व लक्ष्मी प्राप्ति हेतु मन्त्र",
    ritual:
      "Offline पूजा व हवन  आकाश भैरव कल्प के 45 वे अध्याय के 31 पाठ व 3 पाठ के हवन",
  },
  {
    SrNo: 24,
    mantra:
      "ह्रीं हरिहर पुत्राय पुत्र लाभाय शत्रु नाशय मद गज वाहनाय महाशास्ताय नमः॥",
    purpose: "पुत्र प्राप्ति का मन्त्र",
    ritual:
      "Offline पूजा व हवन आकाश भैरव कल्प के 51 वे अध्याय के 21 पाठ व 2 पाठ के हवन",
  },
  {
    SrNo: 25,
    mantra: "आकाश भैरव कल्प के 58 वे अध्याय के 21 पाठ व 2 पाठ के हवन",
    purpose: "दुस्वपनः नाश करने का मन्त्र",
    ritual:
      "Offline पूजा व हवन आकाश भैरव कल्प के 58 वे अध्याय के 21 पाठ व 2 पाठ के हवन",
  },
  {
    SrNo: 26,
    mantra: "आकाश भैरव कल्प के 59 वे अध्याय के 21 पाठ व 2 पाठ के हवन",
    purpose: "जादु टोटको के निवारण का मन्त्र",
    ritual:
      "Offline पूजा व हवन आकाश भैरव कल्प के 59 वे अध्याय के 21 पाठ व 2 पाठ के हवन",
  },
  {
    SrNo: 27,
    mantra:
      "ओम श्रीं ह्रीं क्लीं ग्लों गं गणपतेय वर वरद सर्वजनं मे वशमानय स्वाहा I",
    purpose: "व्यापार व नौकरी के लिये",
    ritual:
      "Offline पूजा व हवन  आकाश भैरव कल्प के 60 वे अध्याय के 16 पाठ व 2 पाठ के हवन",
  },
  {
    SrNo: 28,
    mantra:
      "ॐ देव्ताम्य पितृम्यश्रच  महायोगिम्य एव च I नमः स्वधाय स्वाहाये नित्यमेव नमो नमः II",
    purpose: "पितृदोश  निवारण मन्त्र",
    ritual:
      "Offline पूजा व हवन  आकाश भैरव कल्प के 51 वे अध्याय के 11 पाठ व 1 पाठ के हवन",
  },
  {
    SrNo: 29,
    mantra: "ॐ तत्पुरुषाय विध्महे सुवणेवक्षाय धीमहि त्ननो गरुडः प्रचोदयात I",
    purpose: "कालसर्प् दोष निवारण मन्त्र",
    ritual:
      "Offline पूजा व हवन आकाश भैरव कल्प के 12 वे अध्याय के 27 पाठ व 3 पाठ के हवन",
  },
  {
    SrNo: 30,
    mantra: (
      <p style={{ lineHeight: "22px" }}>
        ओं खं खां खं फट् शत्रून ग्रसीस ग्रसीस हुं फट् सर्वास्त्र
        <br /> संहरणाय शरभाय शान्ताय पक्षिराजाय हूं फट् स्वाहा नमः II{" "}
      </p>
    ),
    purpose: "शत्रुनाश व उनसे जितने का मन्त्र",
    ritual:
      "Offline पूजा व हवन  आकाश भैरव कल्प के 9 वे अध्याय के 31 पाठ व 3 पाठ के हवन",
  },
];

export default MantraData;