

import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
const PrivateRoutes = ({ children }) => {
  const token = localStorage.getItem("token")
  const tokenExpiration = localStorage.getItem("tokenExpiration");
  const now = new Date().getTime();
  const location = useLocation()
  return (
    token && tokenExpiration && now < tokenExpiration ? children : <Navigate to={"/login"} state={location.pathname} replace />

  )
}
export default PrivateRoutes;