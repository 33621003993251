export  const CART_PRODUCT_REQUEST = "CART_PRODUCT_REQUEST"
 export const CART_PRODUCT_FAILURE = "CART_PRODUCT_FAILURE"

// CREATE

  export const CART_ADD_PRODUCT_SUCCESS = "CART_ADD_PRODUCT_SUCCESS"


  // GET
  export const CART_GET_PRODUCT_SUCCESS = "CART_GET_PRODUCT_SUCCESS"


  // DELETE 
  export const CART_DELETE_PRODUCT_SUCCESS = "CART_DELETE_PRODUCT_SUCCESS"