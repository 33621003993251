import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  HStack,
  PinInput,
  PinInputField,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from "axios"
import { useLocation, useNavigate } from "react-router-dom";
const MobileLogin = () => {
  const toast = useToast()
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(false);
  const [pinvisible, setPinVisible] = useState(false);
  const [timer, setTimer] = useState(170); // 2:50 in seconds
  const [sending, setSending] = useState(false); // to control the sending button state
const [enteredPin, setEnteredPin] = useState("")
const [loading, setLoading] = useState(false)
const [pinLoading, setPinLoading] = useState(false)
const navigate = useNavigate()
const location = useLocation()
  const handleChange = (e) => {
    setError(false);
    setPhone(e.target.value);
  };

  const handleGenrateOTP = async() => {
    setPinLoading(true)
    if (phone.length === 10) {
      alert(phone);
  const res = await  mobileRegister()
  if(res){
    setPinVisible(true);
    setSending(true); // disable send button after sending OTP
    setPinLoading(false)
  }
    } else if (!phone) {
      alert("Input field is empty");
      setError(true);
      setPinLoading(false)
    } else {
      alert("Enter valid mobile number");
      setError(true);
      setPinLoading(false)
    }
  };

  useEffect(() => {
    let countdown;
    if (pinvisible && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setPinVisible(false);
      setTimer(170); // Reset timer
      setSending(false); // Enable send button
    }

    return () => clearInterval(countdown);
  }, [pinvisible, timer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };


const mobileRegister = async() => {
   try {
    const response = await axios.post("https://dukhbhanjan.onrender.com/user/moblileRegister", {phone}) 
    if(response.data.success){
      // call register 
      console.log("res",response.data); 
    return response.data.success
    }
   } catch (error) {
    console.log(error);
   setSending(false)
   setPinLoading(false)
   setPinVisible(false)
    alert(error.message)
   }
}

// const genrateOtp = async() => {
//   try {
//     const response = await axios.post("https://dukhbhanjan.onrender.com/user/generate-otp", {phone}) 
//     if(response.data.success){
//       // call pin genrate pin 
//     console.log("res",response.data);
//     }
//    } catch (error) {
//     console.log(error);
//    }
// }


const handleLogin = async() => {
 setLoading(true)
try {
  const res = await axios.post("https://dukhbhanjan.onrender.com/user/verify-otp", {phone, enteredPin})
  if(res){
    localStorage.setItem("token", res.data.token)
    const expirationTime = new Date().getTime() + 2 * 24 * 60 * 60 * 1000; // 2 days in milliseconds
    localStorage.setItem("tokenExpiration", expirationTime);
    setEnteredPin("")
    setPhone("")
    setTimer(170)
    toast({
      title: res.data.msg,
      status: 'success',
      duration: 3000,
      isClosable: true,
     
    })
    location.state ? navigate(location.state, { replace: true }) : navigate("/")
    console.log("res", res);
    setLoading(false)
  
  }
} catch (error) {
  alert(error.message)
  setLoading(false)
  toast({
    title: error.message,
    status: 'error',
    duration: 2000,
    isClosable: true,
  
  })
}
  
}

  return (
    <div data-aos="flip-left">
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>Working on Mobile Login</Heading>
          </Stack>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <Stack spacing={4}>
              <Box display={"flex"} alignItems={"center"} gap={2}>
                <FormControl id="email" isRequired>
                  <FormLabel>Mobile Number</FormLabel>
                  <Input
                    type="number"
                    placeholder="enter mobile number"
                    value={phone}
                    onChange={handleChange}
                    borderColor={error ? "red.300" : undefined}
                  />
                </FormControl>
                {pinLoading ? <Button
                  isLoading
                  mt={8}
                  
                >
                  Send
                </Button> : <Button
                  isDisabled={sending || pinvisible}
                  mt={8}
                  onClick={handleGenrateOTP}
                >
                  Send
                </Button>}
              </Box>

              {/* Pin input box */}
              {pinvisible && (
                <Box>
                  <Text>Verify Pin</Text>
                  <HStack>
                    <PinInput value={enteredPin} onChange={(value) => setEnteredPin(value)}>
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                  <Text color={timer<=10 ? "red":"green"} mt={4}>Time remaining: {formatTime(timer)}</Text>
                </Box>
              )}

          {loading ?     <Button
              isLoading
              loadingText="Loading"
                bg={"yellow.400"}
                color={"white"}
                _hover={{
                  bg: "yellow.500",
                }}
              >
                Login
              </Button> : <Button
              onClick={handleLogin}
                bg={"yellow.400"}
                color={"white"}
                _hover={{
                  bg: "yellow.500",
                }}
              >
                Login
              </Button>}
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </div>
  );
};

export default MobileLogin;
